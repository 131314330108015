import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import SearchIndex from '../components/search/search-index';

const Search = () => (
  <>
    <Helmet title={`Search - Treatment Studio`} />
    <SearchIndex />
  </>
);

export default Search;
