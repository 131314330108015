import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import sal from 'sal.js';
import '../styles/sal.css';

// Components
import ThreeTwo from '../images/three-two';
import { ShortVideo } from '../videos/short-video';

const ProjectContainer = styled.div`
  position: relative;
  grid-column-end: ${props => props.column};
  grid-row-end: ${props => props.rowColumn};

  & .slide-up-animation {
    width: 100%;
    height: 100%;
  }

  & .inner-container {
    overflow: hidden;

    width: 100%;
    height: 100%;
  }

  &:hover .title {
    opacity: 1;
    transform: translateY(0);
  }

  & img {
    z-index: 2;
    transition: 250ms all ease;
  }

  &:hover img {
    transform: scale(1.1);
    opacity: ${props => (props.fadeOutImage ? 0 : 1)};
  }
`;

const Title = styled.div`
  position: absolute;
  z-index: 10;

  opacity: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;

  top: 0;
  left: 0;

  padding: 10px;
  color: #fff;

  transition: 450ms all ease;
  transform: translateY(20px);

  & > div {
    width: calc(100% - 25px);
    line-height: 1.25;
  }

  & h2 {
    font-size: 22px;
  }

  & h3 {
    font-size: 20px;
  }

  & button {
    font-size: 30px;
    color: #fff;

    width: 18px;
    height: 18px;
    line-height: 18px;
  }
`;

const Video = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  z-index: 1;

  overflow: hidden;
`;

const Project = ({ result, index }) => {
  const [currentVideoPlayerId, setcurrentVideoPlayerId] = useState(null);
  const [shouldImageFadeOut, setImageFadeOut] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      sal({
        threshold: 0.2,
        once: true,
      });
    }
  }, []);

  return (
    <ProjectContainer
      fadeOutImage={currentVideoPlayerId === result.id && shouldImageFadeOut}
      column={
        result.image_size === 'Small' || result.image_size === 'Portrait'
          ? `span 1`
          : `span 2`
      }
      rowColumn={
        result.image_size === 'Portrait' || result.image_size === 'Large'
          ? `span 2`
          : `span 1`
      }
      onMouseOver={() => setcurrentVideoPlayerId(result.id)}
      onMouseLeave={() => setcurrentVideoPlayerId(null)}
    >
      <div
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-easing="ease-out-quad"
        className="slide-up-animation"
      >
        {/* <Fade bottom> */}
        <Link to={`/project/${result.uid}`}>
          <div className="inner-container">
            <Title className="title">
              <div>
                <h2>{result.title}</h2>
                <h3>{result.subtitle}</h3>
              </div>
              <button>+</button>
            </Title>
            <ThreeTwo
              image={result.image.url}
              setSize={result.image_size}
              alt={result.image.alt}
              width={result.image.dimensions.width}
              height={result.image.dimensions.height}
            />
            {!isMobile && (
              <Video>
                <ShortVideo
                  url={result.video.url}
                  setImageFadeOut={shouldImageFadeOut =>
                    setImageFadeOut(shouldImageFadeOut)
                  }
                  preload={index <= 2 ? 'metadata' : 'none'}
                  isPlaying={currentVideoPlayerId === result.id ? true : false}
                />
              </Video>
            )}
          </div>
        </Link>
        {/* </Fade> */}
      </div>
    </ProjectContainer>
  );
};

export default Project;
