import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFlexSearch } from 'react-use-flexsearch';

// Components
import Project from './project';

const WorkContainer = styled.div`
  position: relative;

  padding: 60px 0 0 0;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 45px;
  grid-auto-flow: dense;

  @media (min-width: 1500px) {
    max-width: 1400px;
  }

  @media (max-width: 1499px) {
    max-width: 1050px;
  }

  @media (max-width: 767px) {
    padding: 15px 0 0 0;

    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }
`;

const SearchContainer = styled.div`
  padding-top: ${props => props.padding};
  transition: 250ms padding ease;

  width: 100%;
  display: flex;

  & > input {
    margin: 0 auto;

    width: 260px;
    border: 0;
    border-bottom: 1px solid #000;
    border-radius: 0;

    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }
`;

export const Search = ({ index, store }) => {
  const [query, setQuery] = useState('');
  const results = useFlexSearch(query, index, store);

  useEffect(() => {
    if (typeof window !== undefined) {
      document.getElementById('search-input').focus();
    }
  }, []);

  const content = results.map((result, index) => (
    <Project result={result} index={index} key={`search_project_${index}`} />
  ));

  return (
    <>
      <SearchContainer padding={results.length === 0 ? `130px` : `30px`}>
        <input
          type="text"
          value={query}
          onChange={e => setQuery(e.target.value)}
          placeholder="Search..."
          id="search-input"
        />
      </SearchContainer>
      <WorkContainer>{content}</WorkContainer>
    </>
  );
};
