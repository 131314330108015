import React from 'react';

// Components
import { Search } from './search';

// Hooks
import { UseSearchData } from '../hooks/useSearchData';

const SearchIndex = ({}) => {
  const data = UseSearchData();

  if (data !== null) {
    return <Search index={data.index} store={data.store} />;
  } else {
    return null;
  }
};

export default SearchIndex;
